@charset "UTF-8";

/*기본폰트*/
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 100;
	src: local('NotoSansKR-Thin'), local('NotoSansKR-Thin'),
		 url(../fonts/NotoSansKR-Thin.woff2) format('woff2'),
		 url(../fonts/NotoSansKR-Thin.woff) format('woff'),
		 url(../fonts/NotoSansKR-Thin.otf) format('opentype')
  }
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 300;
src: local('NotoSansKR-Light'), local('NotoSansKR-Light'),
        url(../fonts/NotoSansKR-Light.woff2) format('woff2'),
        url(../fonts/NotoSansKR-Light.woff) format('woff'),
        url(../fonts/NotoSansKR-Light.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	src: local('NotoSansKR-Regular'), local('NotoSansKR-Regular'),
		url(../fonts/NotoSansKR-Regular.woff) format('woff2'),
		url(../fonts/NotoSansKR-Regular.woff) format('woff'),
		url(../fonts/NotoSansKR-Regular.otf) format('opentype');
  }

  @font-face {
	 font-family: 'Noto Sans KR';
	 font-style: normal;
	 font-weight: 500;
	 src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
	 	  url(../fonts/NotoSansKR-Medium.woff2) format('woff2'),
		  url(../fonts/NotoSansKR-Medium.woff) format('woff'),
		  url(../fonts/NotoSansKR-Medium.otf) format('opentype');
   }

  @font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 700;
	src: local('NotoSansKR-Bold'), local('NotoSansKR-Bold'),
		  url(../fonts/NotoSansKR-Bold.woff2) format('woff2'),
		 url(../fonts/NotoSansKR-Bold.woff) format('woff'),
		 url(../fonts/NotoSansKR-Bold.otf) format('opentype');
  }

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('roboto-v30-latin-regular'), local('roboto-v30-latin-regular'),
		url(../fonts/roboto-v30-latin-regular.woff2) format('woff2'),
		url(../fonts/roboto-v30-latin-regular.woff) format('woff');
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('roboto-v30-latin-700'), local('roboto-v30-latin-700'),
		url(../fonts/roboto-v30-latin-700.woff2) format('woff2'),
		url(../fonts/roboto-v30-latin-700.woff) format('woff');
}
@font-face {
	font-family: 'GmarketSansTTF';
	font-style: normal;
	font-weight: 400;
	src: local('GmarketSansMedium'), local('GmarketSansMedium'),
		url(../fonts/GmarketSansMedium.woff) format('woff2'),
		url(../fonts/GmarketSansMedium.woff) format('woff'),
		url(../fonts/GmarketSansMedium.otf) format('opentype');
  }
  @font-face {
	font-family: 'GmarketSansTTF';
	font-style: normal;
	font-weight: 700;
	src: local('GmarketSansBold'), local('GmarketSansBold'),
		url(../fonts/GmarketSansBold.woff) format('woff2'),
		url(../fonts/GmarketSansBold.woff) format('woff'),
		url(../fonts/GmarketSansBold.otf) format('opentype');
  }
html, body{height:100%;width:100%;background-color:#0b0c0d;}
html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,a, address, em, img, small, strong, b, u, i, center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, section, summary,time, mark, audio, video 
{margin: 0;padding: 0;font-family:'Noto Sans KR','맑은 고딕',Dotum; letter-spacing: -0.05em;font-weight: 400; font-style: normal;-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: antialiased;}
body {font-size: 14px;line-height: 1.5; font-family: 'Noto Sans KR';font-style: normal;font-weight: 400; word-break: break-all; word-wrap: break-word;color:#7d8693; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: auto;color:#ccc !important;}
a,a:visited {color: inherit;text-decoration: none;}

article,aside,footer,header,nav,section,main {display: block;}
h1, h2, h3, h4, h5, h6{font-weight:normal;}
* {box-sizing: border-box;}
*:before,*:after {box-sizing: inherit;}

table{border-collapse: collapse;border-spacing: 0;table-layout: fixed; width:100%;}

caption{display: none;}

ol,ul {list-style: none;}
img {border-style: none;display: block;}
button{border:none;background-color:transparent;outline: none;cursor: pointer;font-family: 'Noto Sans KR';}
select {-webkit-appearance: none;appearance: none;font-family: 'Noto Sans KR';margin: 0;border:0;font-weight: 400;}
/* label{margin: 0} */
em{font-style: normal;}

/* select::-ms-expand { display: none;}
input[type="text"],
input[type="password"]{height: auto;line-height: normal;width: inherit;outline: none;} */

/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear { display: none; }
::-webkit-input-placeholder {color: #c7c7c7;}
::-moz-placeholder {color: #c7c7c7;}
:-ms-input-placeholder {color: #c7c7c7;}
:-moz-placeholder {color: #c7c7c7;}

textarea::-webkit-input-placeholder {color: #c7c7c7; font-size:13px; font-family: 'Noto Sans KR';}
textarea::-moz-placeholder {color: #c7c7c7;  font-size:13px}
textarea::-ms-input-placeholder {color: #c7c7c7;  font-size:13px}
textarea::-moz-placeholder {color: #c7c7c7;  font-size:13px}

a:link{
 text-decoration: none;
}

.hidden{
	display: none;
}

/* .hidden.active{
	display: table-row;
} */

a:hover{
  color: #fff !important;
}

img{width: inherit;height: inherit;border-radius: inherit;}

.eillipsis{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box; */
    white-space: nowrap;
}

.mulit-line-ellipsis{
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* ellipsis line */
	-webkit-box-orient: vertical;
	line-height: 22px;
	/* height: 2.5em; */
	height: auto;
	min-height: auto;
}

.nav-tabs .nav-link{
	padding: 0;
	border: none;
	background-color: transparent;
}

.nav-tabs .nav-link.active{
	background-color: transparent;
	color:#fff;
}

.nav-link{
	color: inherit;
}

.fade-enter{
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 1;
}

/*component*/
.b-sidebar{
	width: 280px;
	height: calc(100vh - 60px); /* 220613 */
	top: 60px;
	background-color:#222326 !important;
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close{
	position: absolute;
	right: 0;
	background:url('../images/icon_close_panel.svg') no-repeat 0 0 !important;
	font-size: inherit;
	width: 24px;
	height: 24px;
	text-indent: -9999em;
}

.b-sidebar > .b-sidebar-header{
	padding:16px 19px;
}

.b-sidebar-header strong{
	font-size: 16px;
	color:#ccc;
}

.label{
	padding: 0 !important;
}

img{
	width: inherit;
	height: inherit;
}

.form-control {
	padding:0.575rem 0.75rem;
	height: calc(1.4em + 0.85rem + 2px);
}

.form-control-sm {
	padding:7px 0.325rem;
	/* height: calc(1.4em + 0.8rem + 2px); */
	height: 38px;
}


.form-control, .custom-select {
	border:1px solid #424347;
	background-color:#17181a;
	width: 100% ;
	border-radius: 0.25rem;
	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	-ms-border-radius: 0.25rem;
	-o-border-radius: 0.25rem;
	color: #fff;
}

.form-control:focus{
	background-color:#17181a;
	box-shadow: none;
	border-color: #424347;
	color: #ccc;
}

.custom-select{
	height: 38px;
	position: relative;
	padding:8px 38px 4px 12px;
	color: #ccc;
	background-color:#17181a;
	background-image:url('../images/icon_combobox_open.svg');
	background-repeat: no-repeat;
	background-position: 98% center;
	background-size: 24px auto;
}

.custom-select.white{
	background-color: #f0f0f0;
	border-color:#ccc;
	height:44px;
}

.custom-select.white .items div{
	color: #1a1b1f;
}

.custom-select.white .items{
	background-color: #f0f0f0;
	border-color: #ccc;
	top: 40px;
	cursor: pointer;
}

.custom-select.white .items div:hover{
	background-color: #fff;
	color:#1a1b1f;
}
.custom-select.white .items div ~ div{
	border-top: 1px solid #ccc;
}

.custom-select.white .items div:last-child{
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.custom-select.white .selected{
	color:#1a1b1f;
	height: 44px;
	line-height: 40px;
	text-align: left;
}

.custom-control{
	padding-left: 28px;
}

.custom-control.custom-checkbox{
	margin-bottom: 12px;
}

.custom-control.custom-checkbox.single{
	width: 20px;
	margin:0 auto;
}

.custom-control-input{
	width: 1.25rem;
}

.custom-control-label{
	line-height: 1;
}

.custom-control-label span{
	font-size:16px;
	color:#ccc;
	margin-left: 8px;
	line-height: 1;
}

.custom-control-label:after, .custom-control-label:before{
	border-color:#424347;
	background-color: transparent;
	background-repeat: no-repeat;
}

.custom-control-label:before{
	width: 20px;
	height: 20px;
	top: 0;
}

.custom-control-label::after{
	width: inherit;
}

.custom-control-label:before{
	background-color:#17181a
}

.custom-control-input:checked~.custom-control-label::before{
	border-color: #fa601f;
	background-color:#17181a;
	background-image: url('../images/checkbox_checked.svg');
	background-position: center center;
}

.custom-control.white .custom-control-input:checked~.custom-control-label::before{
	border-color: #fa601f;
}


.custom-control.white .custom-control-label:before{
	background-color: #fff;
	border-color: #ccc;
}

.b-form-btn-label-control.form-control > .btn{
	position: absolute;
	top:7px;
	right: 0;
}

.b-form-btn-label-control.form-control > .form-control{
	padding-left: 0.75rem;
}

.btn-secondary{
	background-color:#8a8a8b;
	border-color: transparent;
	padding:0.375rem 2rem;
	color: #fff;
	box-shadow: 0 1px 0 0 #000, inset 0 1px 0 0 #3c3e42;
}

.btn--wrap .btn-secondary{
	box-shadow: 0 1px 0 0 #17181a, inset 0 1px 0 0 #5c6066;
  background-color: #45484d;
}

.btn-dark{
	background-color:#2e3033;
	border-color: #2e3033;
	padding:0.375rem 2rem;
	color: #808080;
	box-shadow: 0 1px 0 0 #000, inset 0 1px 0 0 #3c3e42;
}

.btn-warning{
	background-color: #fd824d;
	border-color:transparent;
	color:#2e3033;
}

.btn-light{
	background-color: #eaeaea;
}

.btn-danger{
	background-color: #fa824d;
	color:#2e3033;
	border-color: #fa824d;
}

.btn-danger:hover{
	background-color: #fa824d;
	color:#2e3033;
	border-color: #fa824d;
}

.bs-tooltip-bottom .arrow::before{
	border:none;
}

.popover .arrow{
	display: none;
}

.custom-select .selected{
	color: #999;
	height: 38px;
	line-height: 36px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 10px;
	cursor: pointer;
}

.custom-select .items {
	width: calc(100% + 2px);
  position: absolute;
  background-color: #17181a;
  top: 36px;
	left: -1px;
  right: 0;
	z-index: 10;
	border:1px solid #424347;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.custom-select .items div {
	text-align: left;
	padding: 8px 10px 4px 10px;
	height: calc(1.5em + 0.85rem + 6px);
  color: #999;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #000;
}

.selectHide {
  display: none;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
	opacity: 1;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
	background-color:#fff;
	border-color: transparent;
	color: #1a1b1f;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle){
	border-radius:5px;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
	-ms-border-radius:5px;
	-o-border-radius:5px;
}

.btn-outline-dark{
	color: #ccc;
	box-shadow: 0 1px 0 0 #17181a;
	border-color: #5e6166;
	line-height: 1.2;
}

.btn--outline.carrot{
	border-width: 2px;
	border-color: #fd824d;
	color:#fd824d;
	font-size: 18px;
	width: 100%;
}

.btn--outline.carrot.lg{
	height: 50px;
}

.form-group{
	margin-bottom: 0;
}

.btn-group-sm>.btn, .btn-sm{
	padding:0.23rem 0.5rem;
	line-height: 1.3;
	min-height: 30px;
}

.segmented-control {
	display: inline-flex;
	background-color: #000;
	padding:6px;
	height: 38px;
	border-radius: 19px;
	-webkit-border-radius:19px;
	-moz-border-radius: 19px;
	-ms-border-radius: 19px;
	-o-border-radius: 19px;
	-webkit-border-radius: 19px;
}

.textarea-control {
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.textarea-control .form-control{
	color:#ccc;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	padding-bottom: 30px;
	resize: none;
}

input[type="text"].form-control:read-only{
	border:none !important
}

.segmented-control input[type=radio] {
  display: none;
}
.segmented-control label {
	border-right: none;
	padding: 4px 14px;
	background: #2e3033;
	color: #ccc;
	font-size: 14px;
	font-weight: 500;
	height: 26px;
	line-height: 1.2;
	cursor: pointer;
	border-radius: 13px;
	-webkit-border-radius: 13px;
	-moz-border-radius: 13px;
	-ms-border-radius: 13px;
	-o-border-radius: 13px;
}
.segmented-control label:first-of-type {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
.segmented-control label:last-of-type {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
	margin-left:6px;
}

.segmented-control input:checked + label {
  background: #fff;
  color: #2e3033;
  cursor: default;
}

.vjs-custom-skin > .video-js {
  width: 100%;
  font-family: "PingFang SC","Helvetica Neue","Hiragino Sans GB","Segoe UI","Microsoft YaHei","微软雅黑",sans-serif;
}

.vjs-custom-skin > .video-js .vjs-menu-button-inline.vjs-slider-active,.vjs-custom-skin > .video-js .vjs-menu-button-inline:focus,.vjs-custom-skin > .video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 10em
}

.vjs-custom-skin > .video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none!important
}

.vjs-custom-skin > .video-js .vjs-control {
  width: 3em
}

.vjs-custom-skin > .video-js .vjs-control.vjs-live-control{
  width: auto;
  padding-left: .5em;
  letter-spacing: .1em;
}

.vjs-custom-skin > .video-js .vjs-menu-button-inline:before {
  width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em
}

.vjs-paused.vjs-has-started.vjs-custom-skin > .video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button {
  display: block
}

.vjs-custom-skin > .video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {
  display: none!important
}

.vjs-custom-skin > .video-js .vjs-mouse-display:after,.vjs-custom-skin > .video-js .vjs-play-progress:after {
  padding: 0 .4em .3em
}

.video-js.vjs-ended .vjs-loading-spinner , .vjs-loading-spinner {
  display: block !important;
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important;
}

.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button,.vjs-paused.vjs-has-started.vjs-custom-skin > .video-js .vjs-big-play-button {
  display: block
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
	z-index: 999;
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0,0,0,0.45);
  font-size: 3.5em;
   /*border-radius: 50%;*/
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important
}

.video-js:hover .vjs-big-play-button,.vjs-custom-skin > .video-js .vjs-big-play-button:focus,.vjs-custom-skin > .video-js .vjs-big-play-button:active {
  background-color: rgba(36,131,213,0.9)
}

.vjs-custom-skin > .video-js .vjs-loading-spinner {
  border-color: rgba(36,131,213,0.8)
}

.vjs-custom-skin > .video-js .vjs-control-bar2 {
  background-color: #000000
}

.vjs-custom-skin > .video-js .vjs-control-bar {
  color: #ffffff;
  font-size: 14px
}

.vjs-custom-skin > .video-js .vjs-play-progress,.vjs-custom-skin > .video-js  .vjs-volume-level {
  background-color: #2483d5
}

.vjs-custom-skin > .video-js .vjs-play-progress:before {
  top: -0.3em;
}

.vjs-custom-skin > .video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.3em;
}

.vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
  left: 0em;
}

.vjs-custom-skin > .video-js .vjs-menu li {
  padding: 0;
  line-height: 2em;
  font-size: 1.1em;
  font-family: "PingFang SC","Helvetica Neue","Hiragino Sans GB","Segoe UI","Microsoft YaHei","微软雅黑",sans-serif;
}

.vjs-custom-skin > .video-js .vjs-time-tooltip,
.vjs-custom-skin > .video-js .vjs-mouse-display:after, 
.vjs-custom-skin > .video-js .vjs-play-progress:after {
  border-radius: 0;
  font-size: 1em;
  padding: 0;
  width: 3em;
  height: 1.5em;
  line-height: 1.5em;
  top: -3em;
}

.vjs-custom-skin > .video-js .vjs-menu-button-popup .vjs-menu {
  width: 5em;
  left: -1em;
}

.vjs-custom-skin > .video-js .vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
  left: 0;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-resolution-button .vjs-menu {
   /*order: 4;*/
}

 /*排序顺序*/
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-play-control {
  order: 0;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control {
  min-width: 1em;
  padding: 0;
  margin: 0 .1em;
  text-align: center;
  display: block;
  order: 1;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-playback-rate .vjs-playback-rate-value{
  font-size: 1.2em;
  line-height: 2.4;
}

.vjs-custom-skin > .video-js .vjs-progress-control.vjs-control {
  order: 2;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-volume-menu-button {
  order: 3;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-resolution-button {
  order: 4;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-resolution-button .vjs-resolution-button-label {
  display: block;
  line-height: 3em;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-playback-rate {
  order: 5;
}

.vjs-custom-skin > .video-js .vjs-control-bar .vjs-fullscreen-control {
  order: 6;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar{
	opacity: 1 !important;
	visibility: visible !important;
}


.vjs-time-control.vjs-time-divider div span{line-height: 3em;}

/* .custom-file-input:lang(ko) ~ .custom-file-label::after {
  content: '파일찾기';
} */


.bg-none{
	background: none !important;
}

/*datapicker*/
.vdp-datepicker__calendar{
	width: 280px;
}

.vdp-datepicker__calendar header .next:after{
	border-left:10px solid #808080 !important
}

.vdp-datepicker__calendar header .prev:after{
	border-right:10px solid #808080 !important
}

.vdp-datepicker__calendar header span{
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	padding:0 5px;
}

.vdp-datepicker__calendar header .prev:not(.disabled):hover, 
.vdp-datepicker__calendar header .next:not(.disabled):hover, 
.vdp-datepicker__calendar header .up:not(.disabled):hover {
	background: #ccc;
	color:#808080;
	font-weight: 500;
	font-family:  Helvetica, sans-serif;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, 
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, 
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover{
	border:1px solid #fa601f !important;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	color:#fa601f;
}

.settings-button-container .toolbox-icon{
	width: 22px;
	height: 22px;
	
}
