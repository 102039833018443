
#paint {
	width: 100%;
	height:calc(100% - 80px);
	position: absolute;
	top: 0;
	left: 0;
	padding:0 117px;
}

#sketch {
	position:relative;
	height: 100%;
	width: 100%;
	padding: 10px;
	border: 1px solid blue;
	margin-top: 20px;
}

#tmp_canvas {
	position: absolute;
	left: 10px; 
	top: 10px;
	cursor: crosshair;
}

#paint-panel {
	display: flex;
	align-items: center;
	/* padding: 0px 10px; */
	width: 100%;
	height: 100%;
	/* float: left; */
	/* border-radius: 4px; */
	background-color: #222326;
	height: 52px;
	border-radius: 5px;
	margin-top: 10px;
}

#paint-panel > .btn-group{
	width: 228px;
}

#paint-panel .btn-group .btn {
	padding:0;
}

#paint-panel .btn-group .btn .icon{
	display: block;
	width: 28px;
	height: 28px;
	background-repeat: no-repeat;
	background-position: center center;
}

#paint-panel .btn-group .btn .icon.undo{
	background-image: url(../images/bx_undo_01.svg);
}

#paint-panel .btn-group .btn .icon.redo{
	background-image: url(../images/bx_redo_01.svg);
}

#paint-panel .btn-group .btn .icon.pencil{
	background-image: url(../images/icon_paint.svg);
}

#paint-panel .btn-group .btn .icon.line{
	background-image: url(../images/icon_draw_line.svg);
}

#paint-panel .btn-group .btn .icon.eraser{
	background-image: url(../images/icon_eraser.svg);
}

#paint-panel .btn-group .btn .icon.square{
	background-image: url(../images/icon_draw_square.svg);
}

#paint-panel .btn-group .btn .icon.circle{
	background-image: url(../images/icon_circle.svg);
}

#paint-panel .btn-group .btn .icon.font{
	background-image: url(../images/icon_font.svg);
}

/* #paint-panel .btn-group .btn .icon.red{
	background-image: url(../images/icon_black_01.svg);
} */

#choose-size {
	display: flex;
}

#choose-size + .btn-group{
	margin-left: 15px;
	padding-left: 15px;
  border-left: 1px solid #000;
}

#paint-panel .title {
	font-weight: bold;
	color: rgba(100,100,100, 0.8);
	font-style: italic;
	font-size: 13px;
	margin-bottom: 5px;
	text-align: center;
}

#paint-panel .btn-group{
	background-color: transparent;
}

#paint-panel .btn-group .btn{
	width: 28px;
	height: 28px;
	margin-right:6px;
	border-radius: 5px;
	opacity: 0.5;
}

#paint-panel .btn-group .btn:hover{
	opacity: 1;
}

#paint-panel .btn-group .btn.active{
	border:1px solid #333;
	opacity: 1;
}

#paint-panel .radio-group {
	display: block;
	/* width: 30px;
	margin: 0 auto; */
}

#paint-panel .radio-group{
	width: 28px;
	height: 28px;
}

#paint-panel .radio-group input[type="radio"] + label:hover{
	opacity: 1;
}

#paint-panel .radio-group input[type="radio"]{
	width: inherit;
	height: inherit;
	display: none;
}

#paint-panel .radio-group input[type="radio"] + label{
	width: 28px;
	height: 28px;
	opacity: 0.5;
	cursor: pointer;
}

#paint-panel .radio-group input[type="radio"]:checked + label{
	border:1px solid #333;
	border-radius: 5px;
	opacity: 1;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

#paint-panel .radio-group input[type="radio"] + label:before{
	content: '';
	display: block;
	width: 28px;
	height: 28px;
	background-repeat: no-repeat;
	background-position: center center;
}

#paint-panel .radio-group input[type="radio"] + label.point-1:before{
	background-image: url(../images/icon_1px_01.svg);
}
#paint-panel .radio-group input[type="radio"] + label.point-2:before{
	background-image: url(../images/icon_2px_01.svg);
}
#paint-panel .radio-group input[type="radio"] + label.point-3:before{
	background-image: url(../images/icon_3px_01.svg);
}

#paint-panel .radio-group input[type="radio"]:before{
	content: '';
	display: block;
	width: 28px;
	height: 28px;
	border: 1px solid red;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url(../images/icon_1px_01.svg);

}

#paint-panel .radio-group input[type="radio"].point-1:before{
	background-image: url(../images/icon_1px_01.svg);
}
#paint-panel .radio-group.point-2{
	background-image: url(../images/icon_1px_01.svg);
}
#paint-panel .radio-group.point-2{
	background-image: url(../images/icon_1px_01.svg);
}

#paint-panel .radio-group input[type="radio"].point-2{
	background-image: url(../images/icon_2px_01.svg);
}

#paint-panel .radio-group input[type="radio"].point-3{
	background-image: url(../images/icon_3px_01.svg);
}

#paint-panel .radio-group input[type="radio"]:before{

}

#color-panel .btn-group {
	/* border: 1px solid gray; */
}

#color-panel .btn {
	width: 28px;
	height: 28px;
	/* border: 1px solid rgba(200,200,200,0.8); */
}

#color-panel {
	margin-left: 20px;
	display: inline-block;
	/* border-right: 1px solid #000; */
}

#paint-undo-redo {
	display: flex;
	padding:0 15px;
	/* margin-left: 30px; */
	position: relative;
	border-right: 1px solid #000;
}

#paint-undo-redo {
	display: flex;
	padding:0 15px;
	/* margin-left: 30px; */
	position: relative;
	border-right: 1px solid #000;
}

#paint-clear {
	margin-left: 30px;
	
}

#text_tool {
    position: absolute;
    border: 1px dashed black;
    outline: 0;
    display: none;
    
    padding: 4px 8px; 
    font: 14px;
    overflow: hidden;
    white-space: nowrap;
}