.cresto--wrap.autoHeight{
    height: auto;
    overflow: auto;
}
.cresto--wrap.responsive{
    min-width: 100%;
    overflow-x: hidden;
}
.intro_wrap .inner{
    width: 1228px;
    margin: 0 auto;
}
.hasPop .intro_header{
    top: 66rem;
}
.hasPop .intro_visual{
    margin-top: 104rem;
}
.m_noti_pop{
    display: none;
    width: 100%;
    height: 66rem;
    background-color: #4ab5b5;
    border-radius: 0 0 15rem 15rem;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10rem;
    font-size: 8rem;
    line-height: 11rem;
    letter-spacing: -0.32rem;
    color: #fff;
}
.hasPop .m_noti_pop{
    display: block;
}
.hasPop .m_noti_pop .popBtn{
    position: absolute;
    right: 10rem;
    bottom: 8rem;
    display: flex;
    gap: 0 6rem;
}
.hasPop .m_noti_pop .popBtn a{
    display: block;
    font-size: 7rem;
    letter-spacing: -0.27rem;
    color: #4ab5b5;
    background: #fff;
    padding: 2rem 8rem 3rem;
    border-radius: 15rem;;
}
.hasPop .m_noti_pop .popBtn a:hover{
    color: #4ab5b5 !important;
}
.intro_header .desc_txt{
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.intro_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 25px 27px 35px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    transition: background 0.3s;
    width: 100%;
}
.intro_header.active{
    position: fixed;
    width: calc(100% - 10px);
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
}
.hasPop .intro_header.active{
    top: 0;
    transition: top 0.3s;
}
.intro_header h1{
    width: 308px;
    height: 44px;
}
.intro_header h1 a{
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/logo-white.png) no-repeat top left;
    background-size: contain;
}
.intro_header.active h1 a{
    background: url(../images/logo-black.png) no-repeat top left;
    background-size: contain;
}
.intro_header.w_bg h1 a{
    background: url(../images/logo-black.png) no-repeat top left;
    background-size: contain;
}
.intro_header .intro_gnb{
    display: flex;
    align-items: center;
    gap: 0 10px;
}
.intro_header .intro_gnb li > a{
    display: block;
    width: auto;
    height: 45px;
    font-size: 17px;
    font-weight: bold;
    color: #333;
    letter-spacing: -0.68px;
    padding: 0 20px;
    line-height: 40px;
    border-radius: 22.5px;
    border: solid 2px #2e2e2e;
    background-color: rgba(255, 255, 255, 0.2);
}
.intro_header .intro_gnb li > a:hover{
    color: #333 !important;
}
.intro_header .intro_gnb li.pdf_btn > a{
    border:none;
    border-radius: 22.5px;
    /* background: rgba(51, 51, 51, 0.1); */
    background-color: #b1e3e3;
    line-height: 42px;
    display: flex;
    align-items: center;
}
.intro_header .intro_gnb li .icon-pdf{
    display: block;
    width: 32px;
    height: 19px;
    background: url(../images/pdf.png);
    margin-right: 3.7px;
    margin-top: 1px;
}
.intro_header .intro_gnb li.profile > a{
    border:none;
    background:none;
    text-decoration: underline;
    padding-left: 7px;
    padding-right: 10px;
    line-height: 45px;
}
.intro_header .intro_gnb li.loginBtn{
    position: relative;
}
.login_modal{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
}
.login_modal .modal_bg{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.login_modal .login_modal_box{
    position: absolute;
    right: 27px;
    top: 78px;
    padding: 49px 40px 24px;
    width: 420px;
    height: 300px;
    border-radius: 15px;
    background-color: #fff;
    text-align: center;
}
.login_modal.center .login_modal_box{
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.login_modal .login_tit{
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.8px;
    color: #333;
    margin-bottom: 12px;
}
.login_modal span{
    display: block;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #555;
}
.login_modal .goLogin{
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 4px;
    border-radius: 268px;
    background-color: #fa6000;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #fff;
    margin-top: 22px;
}
.login_modal .goLogin i{
    display: block;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #fff;
    background-image: url(../images/icon_g.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.login_modal .goLogin em{
    display: block;
    width: calc(100% - 38px);
}
.login_modal .policy_txt{
    text-align-last: left;
    line-height: 20px;
    margin-top: 23px;
}
.login_modal .policy_txt a{
    font-weight: bold;
    color: #333;
    text-decoration: underline;
}
.login_modal .policy_txt a:hover{
    color: #333 !important;
}
.login_modal .modal_close{
    position: absolute;
    right: 24px;
    top: 24px;
    width: 21px;
    height: 21px;
    background: url(../images/icon-close.png) no-repeat;
}
.login_modal2{
    width: auto;
    height: auto;
    overflow: visible;
    position: absolute;
    z-index: 999;
}
.login_modal2 .modal_bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
}
.intro_visual{
    width: 100%;
    height: 100vh;
    background: #f5f7fa url(../images/main_bg.png) no-repeat center center;
    background-size: cover;
    position: relative;
}
.intro_visual .intro_visual_bg{
    display: none;
    width: 100%;    
}
.intro_visual .intro_visual_bg img{
    display: none;
}
.intro_visual .intro_visual_obj{
    position: absolute;
    display: block;
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    z-index: 0;
}
.intro_visual .intro_visual_obj1{
    width: 63%;
    padding-bottom: 84%;
    max-width: 1445px;
    /* width: 1211px;
    height: 1013px; */
    background-image: url(../images/main_obj1.png);
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(-40%);
    transition: all 0.5s;
}
.intro_visual .intro_visual_obj2{
    width: 36.198%;
    padding-bottom: 45.21%;
    /* width: 695px;
    height: 868px; */
    background-image: url(../images/main_obj2.png);
    top: 91px;
    right: 0;
    opacity: 0;
    transform: translateX(40%);
    transition: all 0.5s;
}
.intro_visual .inner{
    min-width: 1228px;
    width: 64%;
    height: 100%;
    position: relative;
    z-index: 2;
}
.intro_visual h2{
    position: absolute;
    left: 0;
    top: 17%;
    color: #fff;
}
.intro_visual h2 span{
    display: block;
}
.intro_visual h2 .h2_stxt1{
    font-size: 34px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -3.4px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.6s ease-out;
}
.intro_visual h2 .h2_stxt2{
    font-size: 50px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: -1.5px;
    margin-top: 25px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.6s ease-out;
}
.intro_visual h2 p{
    font-family: GmarketSansTTF;
    font-size: 80px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -4.8px;
    margin-top: 48px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.6s ease-out;
}
.intro_visual h2 p b{
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
    line-height: inherit;
    letter-spacing: inherit;
}
.intro_visual .visual_symbol{
    width: 498px;
    height: 450px;
    /* background:url(../images/intro_symbol.png) no-repeat center center; */
    position: absolute;
    left: 48%;
    top: 15%;
    opacity: 0;
    transform: 
        scale(1.3) 
        /* rotate(-115deg) */
        ;
    transition: all 0.8s ease-out;
}
.intro_visual .visual_symbol span{
    display: block;
    width: 100%;
    height: 100%;
    background:url(../images/intro_symbol.png) no-repeat center center;
    background-size: contain;
}
.intro_visual.show .intro_visual_obj1{
    opacity: 1;
    transform: translateX(0);
}
.intro_visual.show .intro_visual_obj2{
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.1s;
}
.intro_visual.show h2 .h2_stxt1{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
}
.intro_visual.show h2 .h2_stxt2{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
}
.intro_visual.show h2 p{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.7s;
}
.intro_visual.show .visual_symbol{
    opacity: 1;
    transform: scale(1) rotate(0);
    transition-delay: 1s;
}
.intro_visual.done .intro_visual_obj1,
.intro_visual.done .intro_visual_obj2,
.intro_visual.done h2 .h2_stxt1,
.intro_visual.done h2 .h2_stxt2,
.intro_visual.done h2 p,
.intro_visual.done .visual_symbol{
    transition: none;
}
.intro_contents{
    position: relative;
    z-index: 1;
}
.intro_contents .intro_section{
    position: relative;
}
.intro_contents .intro_section:before{
    content:"";
    position: absolute;
    z-index: -1;
}
.intro_contents .intro_section1{
    padding-top: 87px;
    padding-bottom: 200px;
}
.intro_contents .intro_section1:before{
    width: 100%;
    height: 976px;
    background: url(../images/sec1_bg.png) no-repeat center bottom;
    background-size: cover;
    left: 0;
    top: 0;
}
.intro_contents .intro_section1 .desc_ico_list{
    display: flex;
    justify-content: space-around;
    gap: 0 84px;
    padding-bottom: 76px;
}
.intro_contents .intro_section1 .desc_ico_list li i{
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 30px;
    box-shadow: 4px 5.7px 20px 0 rgba(0, 74, 146, 0.1);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    margin: 0 auto;
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico1 i{
    background-image: url(../images/main_ico_01.png);
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico2 i{
    background-image: url(../images/main_ico_02.png);
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico3 i{
    background-image: url(../images/main_ico_03.png);
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico4 i{
    background-image: url(../images/main_ico_04.png);
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico5 i{
    background-image: url(../images/main_ico_05.png);
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico6 i{
    background-image: url(../images/main_ico_06.png);
}
.intro_contents .intro_section1 .desc_ico_list li.desc_ico7 i{
    background-image: url(../images/main_ico_07.png);
}
.intro_contents .intro_section1 .desc_ico_list li p{
    font-size: 26px;
    font-weight: bold;
    letter-spacing: -1.04px;
    text-align: center;
    color: #333;
    margin-top: 8.5px;
}
.intro_contents .intro_section1 .desc_h3{
    text-align: center;
}
.intro_contents .intro_section1 .desc_h3 h3{
    font-size: 60px;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: -2.4px;
    text-align: center;
    color: #333;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section1 .desc_h3 h3 br{
    display: none;
}
.intro_contents .intro_section1 .desc_h3 p{
    font-size: 28px;
    line-height: 1.43;
    letter-spacing: -1.12px;
    text-align: center;
    color: #555;
    margin-top: 26px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section1 .desc_ico_list li{
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section1.show .desc_ico_list li{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(1){
    transition-delay: 0s;
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(2){
    transition-delay: 0.1s;
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(3){
    transition-delay: 0.2s;
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(4){
    transition-delay: 0.3s;
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(5){
    transition-delay: 0.4s;
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(6){
    transition-delay: 0.5s;
}
.intro_contents .intro_section1.show .desc_ico_list li:nth-child(7){
    transition-delay: 0.6s;
}
.intro_contents .intro_section1.show .desc_h3 h3{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.8s;
}
.intro_contents .intro_section1.show .desc_h3 p{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1s;
}
.intro_contents .intro_section1.done .desc_ico_list li,
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(1),
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(2),
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(3),
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(4),
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(5),
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(6),
.intro_contents .intro_section1.done .desc_ico_list li:nth-child(7),
.intro_contents .intro_section1.done .desc_h3 h3,
.intro_contents .intro_section1.done .desc_h3 p{
    transition: none;
}
.intro_contents .intro_section .imgbox{
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.4);
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.6s ease-out;
    width: 1080px;
    max-width: 100%;
}
/* .intro_contents .intro_section .imgbox.s_imgbox{
    width: 987px;
}
.intro_contents .intro_section .imgbox.b_imgbox{
    width: 1228px;
} */
.intro_contents .intro_section .imgbox img{
    width: 100%;
}
.intro_contents .intro_section .txtbox{
    margin-top: 46px;
    text-align: center;
}
.intro_contents .intro_section .txtbox p{
    font-size: 45px;
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: -1.8px;    
    color: #333;
    margin-bottom: 24px;
    word-break: keep-all;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section .txtbox span{
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: -0.8px;
    color: #555;
    opacity: 0;
    display: block;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section.show .imgbox{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section.show .txtbox p{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section.show .txtbox span{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section.done .imgbox,
.intro_contents .intro_section.done .txtbox p,
.intro_contents .intro_section.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section2{
    padding-top: 129px;
    padding-bottom: 148px;
}
.intro_contents .intro_section2:before{
    width: 989px;
    height: 1117px;
    background: url(../images/sec2_obj.png) no-repeat left top;
    left: 0;
    top: 0;
}
.intro_contents .intro_section2 .imgbox{
    box-shadow: none;
    width: 999px;
}
.intro_contents .intro_section2 .imgbox img{
    display: none;
}
.intro_contents .intro_section2 .imgbox .web_img{
    display: block;
}
.intro_contents .intro_section2.show .txtbox p{
    transition-delay: 0.5s;
}
.intro_contents .intro_section2.show .txtbox span{
    transition-delay: 0.7s;
}
.intro_contents .intro_section2.done .txtbox p,
.intro_contents .intro_section2.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section3{
    padding-bottom: 160px;
}
.intro_contents .intro_section3 .i_sec3_tab{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 10px;
    margin: 0 auto 22px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section3 .i_sec3_tab li a{
    display: block;
    width: 146px;
    height: 56px;
    border-radius: 50px;
    border: solid 2px #2e2e2e;
    background-color: #fff;
    text-align: center;
    line-height: 52px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #2e2e2e;
}
.intro_contents .intro_section3 .i_sec3_tab li.active a,
.intro_contents .intro_section3 .i_sec3_tab li a:hover{
    background-color: #2e2e2e;
    color: #fff;
}
.intro_contents .intro_section3 .imgbox{
    position: relative;
}
.intro_contents .intro_section3 .imgbox .swiper-button-prev,
.intro_contents .intro_section3 .imgbox .swiper-button-next{
    width: 31px;
    height: 62px;
    background-image: url(../images/slide_arrow.png);
    background-size: 100%;
}
.intro_contents .intro_section3 .imgbox .swiper-button-prev{
    left: -71px;
}
.intro_contents .intro_section3 .imgbox .swiper-button-next{
    transform: rotate(180deg);
    right: -71px;
}
.intro_contents .intro_section3.show .i_sec3_tab{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section3.show .imgbox{
    transition-delay: 0.2s;
}
.intro_contents .intro_section3.show .txtbox p{
    transition-delay: 0.7s;
}
.intro_contents .intro_section3.show .txtbox span{
    transition-delay: 0.9s;
}
.intro_contents .intro_section3.done .i_sec3_tab,
.intro_contents .intro_section3.done .imgbox,
.intro_contents .intro_section3.done .txtbox p,
.intro_contents .intro_section3.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section4{
    padding-bottom: 160px;
}
.intro_contents .intro_section4:before{
    width: 1074px;
    height: 1181px;
    right: 0;
    top: -245px;
    background:url(../images/sec4_obj.png) no-repeat right top;
}
.intro_contents .intro_section4 .inner{
    position: relative;
}
.intro_contents .intro_section4 .swiper-slide{
    min-width: 0;
}
.intro_contents .intro_section4 .imgbox img{
    height: auto;
}
.intro_contents .intro_section4 .swiper-pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    margin-top: 39px;
    display: none;
}
.intro_contents .intro_section4 .swiper-pagination .swiper-pagination-bullet{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.2;
}
.intro_contents .intro_section4 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
    opacity: 1;
}
.intro_contents .intro_section4 .i_sec4_tab{
    position: absolute;
    top: 0;
    left: calc(50% + 540px);
    margin-left: 9px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section4 .i_sec4_tab li{
    margin-bottom: 11px;
    border-radius: 5px;
    border: 3px solid #999;
}
.intro_contents .intro_section4 .i_sec4_tab li:last-child{
    margin-bottom: 0;
}
.intro_contents .intro_section4 .i_sec4_tab li.active{
    border-color: #fa6000;
}
.intro_contents .intro_section4.show .i_sec4_tab{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
}
.intro_contents .intro_section4.show .txtbox p{
    transition-delay: 0.7s;
}
.intro_contents .intro_section4.show .txtbox span{
    transition-delay: 0.9s;
}
.intro_contents .intro_section4.done .i_sec4_tab,
.intro_contents .intro_section4.done .txtbox p,
.intro_contents .intro_section4.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section5{
    padding-bottom: 160px;
}
.intro_contents .intro_section5:before{
    width: 950px;
    height: 1060px;
    background: url(../images/sec5_obj.png) no-repeat left top;
    left: 0;
    top: -263px;
}
.intro_contents .intro_section5.show .txtbox p{
    transition-delay: 0.4s;
}
.intro_contents .intro_section5.show .txtbox span{
    transition-delay: 0.6s;
}
.intro_contents .intro_section5.done .txtbox p,
.intro_contents .intro_section5.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section6{
    padding-bottom: 160px;
}
.intro_contents .intro_section6:before{
    width: 1074px;
    height: 1181px;
    background: url(../images/sec6_obj.png) no-repeat right top;
    right: 0;
    top: -175px;
}
.intro_contents .intro_section6.show .txtbox p{
    transition-delay: 0.4s;
}
.intro_contents .intro_section6.show .txtbox span{
    transition-delay: 0.6s;
}
.intro_contents .intro_section6.done .txtbox p,
.intro_contents .intro_section6.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section7{
    padding-bottom: 160px;
}
.intro_contents .intro_section7:before{
    width: 950px;
    height: 1060px;
    background: url(../images/sec7_obj.png) no-repeat right top;
    left:0;
    top: -261px;
}
.intro_contents .intro_section7.show .txtbox p{
    transition-delay: 0.4s;
}
.intro_contents .intro_section7.show .txtbox span{
    transition-delay: 0.6s;
}
.intro_contents .intro_section7.done .txtbox p,
.intro_contents .intro_section7.done .txtbox span{
    transition: none;
}
.intro_contents .intro_section8 .txtbox{
    padding-bottom: 81px;
}
.intro_contents .intro_section8 .txtbox p{
    font-size: 50px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: -2px;
    color: #3d3c3a;
    margin-bottom: 20px;
}
.intro_contents .intro_section8 .txtbox span b{
    font-weight: bold;
    color: #333;
}
.intro_contents .intro_section8 .balloon_box{
    height: 696px;
    background: url(../images/balloon-bg.png) no-repeat bottom center;
    position: relative;
}
.intro_contents .intro_section8 .balloon_box .inner{
    position: relative;
    height: 100%;
}
.intro_contents .intro_section8 .balloon_box .balloon_bg{
    display: block;
    width: 1091px;
    height: 616px;
    background: url(../images/balloon_line.png) no-repeat left top;
    position: absolute;
    right: 0;
    bottom: 0;
}
.intro_contents .intro_section8 .balloon_box .balloon li{
    width: 228px;
    height: 275px;
    padding-top: 63px;
    text-align: center;
    color: #fff;
    background: url(../images/balloon_1.png) no-repeat center top;
    position: absolute;
    z-index: 2;
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.4s ease-out;
}
.intro_contents .intro_section8 .balloon_box .balloon li p{
    font-size: 25px;
    font-weight: bold;
    line-height: 1.44;
    letter-spacing: -1px;
    margin-bottom: 14px;
}
.intro_contents .intro_section8 .balloon_box .balloon li span{
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: -0.68px;
}
.intro_contents .intro_section8 .balloon_box .balloon li.balloon_1{
    left: 71px;
    top: -102px;
}
.intro_contents .intro_section8 .balloon_box .balloon li.balloon_2{
    left: 525px;   
    top: -13px;
}
.intro_contents .intro_section8 .balloon_box .balloon li.balloon_3{
    width: 300px;
    height: 348px;
    background: url(../images/balloon_3.png) no-repeat center top;
    left: 854px;
    bottom: 66px;
}
.intro_contents .intro_section8 .balloon_box .balloon li.balloon_3 p{
    font-size: 36px;
    line-height: 1;
    margin-bottom: 27px;
}
.intro_contents .intro_section8 .balloon_box .balloon li.balloon_3 span{
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.8px;
}
.intro_contents .intro_section8 .balloon_box .balloon_mbox{
    display: none;
    opacity: 0;
    transform: translateY(20%);
    transition: all 0.4s ease-out;
}
.intro_contents .intro_section8 .balloon_box .balloon_mbox img{
    display: none;
    width: 100%;
}
.intro_contents .intro_section8.show .txtbox span{
    transition-delay: 0.2s;
}
.intro_contents .intro_section8.show .balloon_box .balloon li{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section8.show .balloon_box .balloon li:nth-child(1){
    transition-delay: 0.4s;
}
.intro_contents .intro_section8.show .balloon_box .balloon li:nth-child(2){
    transition-delay: 0.6s;
}
.intro_contents .intro_section8.show .balloon_box .balloon li:nth-child(3){
    transition-delay: 0.8s;
}
.intro_contents .intro_section8.show .balloon_box .balloon_mbox{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s;
}
.intro_contents .intro_section8.done .txtbox span,
.intro_contents .intro_section8.done .balloon_box .balloon li,
.intro_contents .intro_section8.done .balloon_box .balloon li:nth-child(1),
.intro_contents .intro_section8.done .balloon_box .balloon li:nth-child(2),
.intro_contents .intro_section8.done .balloon_box .balloon li:nth-child(3),
.intro_contents .intro_section8.done .balloon_box .balloon_mbox{
    transition: none;
}
.intro_contents .intro_section9{
    padding-top: 126px;
    padding-bottom: 135px;
}
.intro_contents .intro_section9 h4{
    font-size: 50px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -2px;
    text-align: center;
    color: #333;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s;
}
.intro_contents .intro_section9 .startBtn{
    display: block;
    width: 400px;
    height: 82px;
    border-radius: 30px;
    background-color: #fa6000;
    font-size: 30px;
    font-weight: bold;
    line-height: 82px;
    letter-spacing: -1.2px;
    text-align: center;
    color: #fff;
    margin: 49px auto 0;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s ease-out;
}
.intro_contents .intro_section9 .cloud_list{
    display: flex;
    gap: 0 20px;
    justify-content: space-between;
    padding-top: 85px;
}
.intro_contents .intro_section9 .cloud_list li{
    width: calc(33.333% - 13.334px);
    position: relative;
    padding-left: 72px;
    opacity: 0;
    transform: translateY(40%);
    transition: all 0.3s;
}
.intro_contents .intro_section9 .cloud_list li em{
    display: flex;
    align-items: center;
    width: 58px;
    height: 71px;
    font-size: 26px;
    letter-spacing: -1.04px;
    text-align: center;
    color: #38a5a5;
    background:url(../images/order_obj.png) no-repeat;
    padding-left: 9px;
    position: absolute;
    left: 0;
    top: -12px;
}
.intro_contents .intro_section9 .cloud_list li p{
    font-size: 34px;
    font-weight: bold;
    line-height: 1.24;
    letter-spacing: -1.36px;
    color: #3d3c3a;
    margin-bottom: 9px;
}
.intro_contents .intro_section9 .cloud_list li span{
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: -0.8px;
    color: #555;
}
.intro_contents .intro_section9.show h4{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section9.show .startBtn{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
}
.intro_contents .intro_section9.show .cloud_list li{
    opacity: 1;
    transform: translateY(0);
}
.intro_contents .intro_section9.show .cloud_list li:nth-child(1){
    transition-delay: 0.4s;
}
.intro_contents .intro_section9.show .cloud_list li:nth-child(2){
    transition-delay: 0.6s;
}
.intro_contents .intro_section9.show .cloud_list li:nth-child(3){
    transition-delay: 0.8s;
}
.intro_contents .intro_section9.done h4,
.intro_contents .intro_section9.done .startBtn,
.intro_contents .intro_section9.done .cloud_list li,
.intro_contents .intro_section9.done .cloud_list li:nth-child(1),
.intro_contents .intro_section9.done .cloud_list li:nth-child(2),
.intro_contents .intro_section9.done .cloud_list li:nth-child(3){
    transition: none;
}
.intro_footer{
    background-color: #f5f7fa;
    padding: 50px 0 75px;
    position: relative;
}
.intro_footer .inner{
    position: relative;
}
.intro_footer .footer_logo{
    width: 308px;
    height: 44px;
    background:url(../images/logo-black.png) no-repeat;
}
.intro_footer .footer_txtbox{
    margin-top: 25px;
    display: flex;
}
.intro_footer .footer_txtbox .footer_txtbox_left{
    width: 60%;
}
.intro_footer .footer_txtbox .footer_txtbox_right{
    width: 40%;
}
.intro_footer .footer_txtbox .footer_menu{
    display: flex;
    gap: 0 37px;
    align-items: center;
    margin-bottom: 7px;
}
.intro_footer .footer_txtbox .footer_menu li a{
    font-size: 17px;
    font-weight: 500;
    line-height: 1.65;
    letter-spacing: -0.68px;
    color: #333;
    text-decoration: underline;
}
.intro_footer .footer_txtbox .footer_menu li a:hover{
    color: #333 !important;
}
.intro_footer .footer_txtbox .footer_menu li a b{
    font-weight: bold;
}
.intro_footer .footer_txtbox .footer_info{
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.68px;
    color: #555;
}
.intro_footer .footer_txtbox .footer_info span{
    display: block;
}
.intro_footer .footer_txtbox .footer_info span em{
    display: inline-block;
    padding: 0 10px;
    position: relative;
}
.intro_footer .footer_txtbox .footer_info span em:after{
    content:"";
    width: 1px;
    height: 14px;
    background-color: #cccccc;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.intro_footer .footer_txtbox .footer_info span em:first-child{
    padding-left: 0;
}
.intro_footer .footer_txtbox .footer_info span em:last-child{
    padding-right: 0;;
}
.intro_footer .footer_txtbox .footer_info span em:last-child:after{
    width: 0;
    height: 0;
}
.intro_footer .footer_txtbox .footer_info span b{
    font-weight: bold;
}
.intro_footer .footer_txtbox .footer_info .copy{
    font-size: 15px;
}
.intro_footer .footer_txtbox .footer_contact{
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 39px;
}
.intro_footer .footer_txtbox .footer_contact span{
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.8px;
    color: #333;
    display: flex;
    align-items: center;
    margin-right: 37px;
}
.intro_footer .footer_txtbox .footer_contact span:last-child{
    margin-right: 0;
}
.intro_footer .footer_txtbox .footer_contact span i{
    display: inline-block;
    margin-right: 8px;
}
.intro_footer .footer_txtbox .footer_contact .number i{
    width: 25px;
    height: 25px;
    background: url(../images/icon-phone.png) no-repeat;
}
.intro_footer .footer_txtbox .footer_contact .mail i{
    width: 25px;
    height: 21px;
    background: url(../images/icon-email.png) no-repeat;
}
.intro_footer .topBtn{
    position: absolute;
    right: 0;
    /* bottom: 97px; */
    top: 0;
    width: 50px;
    height: 50px;
    background:#eaeaea;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro_footer .topBtn:hover{
    color: #333 !important;
}
.intro_footer .topBtn i{
    display: block;
    width: 16px;
    height: 26px;
    background: url(../images/top_arrow.png) no-repeat;
    margin: 0 auto;
}
.term_cont{
    padding-top: 100px;
    padding-bottom: 135px;
}
.term_cont h2{
    padding-top: 86px;
    text-align: center;
    font-size: 45px;
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: -1.8px;
    color: #333;
}
.term_cont .title_desc{
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: -0.8px;
    color: #555;
    margin-top: 31px;
}
.term_cont .term_box{
    margin-top: 45px;
}
.term_cont .term_box h3{
    font-size: 26px;
    font-weight: bold;
    line-height: 1.42;
    letter-spacing: -1.04px;
    color: #333;
    margin-bottom: 24px;
}
.term_cont .term_box .term_tit{
    font-size: 22px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: -0.88px;
    color: #333;
}
.term_cont .term_box span{
    display: block;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.8px;
    color: #555;
    margin-top: 12px;
}
.term_cont .term_box ul li{
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.8px;
    color: #555;
    position: relative;
}
.term_cont .term_box ul li:before{
    position: absolute;
    left: 0;
    top:0;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.8px;
    color: #555;
}
.term_cont .term_box .r_num{
    margin-top: 11px;
}
.term_cont .term_box .r_num > li{
    padding-left: 24px;
}
.term_cont .term_box .r_num > li:nth-child(1):before{
    content:"①";
}
.term_cont .term_box .r_num > li:nth-child(2):before{
    content:"②";
}
.term_cont .term_box .r_num > li:nth-child(3):before{
    content:"③";
}
.term_cont .term_box .r_num > li:nth-child(4):before{
    content:"④";
}
.term_cont .term_box .r_num > li:nth-child(5):before{
    content:"⑤";
}
.term_cont .term_box .r_num > li:nth-child(6):before{
    content:"⑥";
}
.term_cont .term_box .r_num > li:nth-child(7):before{
    content:"⑦";
}
.term_cont .term_box .r_num > li:nth-child(8):before{
    content:"⑧";
}
.term_cont .term_box .r_num > li:nth-child(9):before{
    content:"⑨";
}
.term_cont .term_box .r_num > li:nth-child(10):before{
    content:"⑩";
}
.term_cont .term_box ul ul{
    margin: 11px 0 17px;

}
.term_cont .term_box .o_num > li{
    padding-left: 20px;
}
.term_cont .term_box .o_num > li:nth-child(1):before{
    content:"1.";
}
.term_cont .term_box .o_num > li:nth-child(2):before{
    content:"2.";
}
.term_cont .term_box .o_num > li:nth-child(3):before{
    content:"3.";
}
.term_cont .term_box .o_num > li:nth-child(4):before{
    content:"4.";
}
.term_cont .term_box .o_num > li:nth-child(5):before{
    content:"5.";
}
.term_cont .term_box .o_num > li:nth-child(6):before{
    content:"6.";
}
.term_cont .term_box .o_num > li:nth-child(7):before{
    content:"7.";
}
.term_cont .term_box .o_num > li:nth-child(8):before{
    content:"8.";
}
.term_cont .term_box .o_num > li:nth-child(9):before{
    content:"9.";
}
.term_cont .term_box .o_num > li:nth-child(10){
    padding-left: 30px;
}
.term_cont .term_box .o_num > li:nth-child(10):before{
    content:"10.";
}
.term_cont .term_box .o_num > li:nth-child(11){
    padding-left: 30px;
}
.term_cont .term_box .o_num > li:nth-child(11):before{
    content:"11.";
}
.term_cont .term_box .o_num > li:nth-child(12){
    padding-left: 30px;
}
.term_cont .term_box .o_num > li:nth-child(12):before{
    content:"12.";
}
.term_cont .term_box .o_num > li:nth-child(13){
    padding-left: 30px;
}
.term_cont .term_box .o_num > li:nth-child(13):before{
    content:"13.";
}
.term_cont .term_box .o_num > li:nth-child(14){
    padding-left: 30px;
}
.term_cont .term_box .o_num > li:nth-child(14):before{
    content:"14.";
}
.term_cont .term_box .mt--0{
    margin-top: 0;
}
.term_cont .term_box .mb--0{
    margin-top: 0;
}
.term_cont .term_box .term_tit.bylaw_tit{
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: -0.8px;
}
.term_cont .term_box .term_col{
    margin-bottom: 24px;
}
.term_cont .term_box .term_col:last-child{
    margin-bottom: 0;
}
.term_cont .term_box ul li a{
    color: #0056b3;
}
.term_cont .term_box ul li a:hover{
    color: #0056b3 !important;
    text-decoration: underline;
}
.index_wrap .intro_contents .intro_section8{
    padding-top: 104px;
    padding-bottom: 124px;
}
.index_wrap .intro_contents .intro_section9 .textbox{
    text-align: center;
    position: relative;
}
.index_wrap .intro_contents .intro_section9 .textbox .textbox_bg{
    display: block;
    width: 100%;
    max-width: 813px;
    margin: 0 auto;
}
.index_wrap .intro_contents .intro_section9 .textbox .textbox_bg img{
    width: 100%;
}
.index_wrap .intro_contents .intro_section9 .textbox p{
    width: 100%;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -2px;
    color:#10100f;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
}
.index_wrap .intro_contents .intro_section9 .textbox p b{
    font-weight: bold;
}
.index_wrap .intro_contents .intro_section9 .textbox p em{
    display: block;
    font-size: 85px;
    font-weight: bold;
    line-height: 0.71;
    letter-spacing: -5.1px;
    color: #fff;
    margin-top: 32px;
}
.wait_cont{
    padding-top: 100px;
    padding-bottom: 235px;
    text-align: center;
}
.wait_cont .wait_h3{
    padding-top: 163px;
    font-size: 35px;
    letter-spacing: -1.4px;
    color: #333;
    font-weight: bold;
    word-break: keep-all;
}
.wait_cont .wait_desc{
    margin-top: 31px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.8px;
    color: #555;
    word-break: keep-all;
}
.wait_cont .wait_info{
    width: 100%;
    max-width: 1020px;
    background-color: #f5f5f5;
    text-align-last: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 37px 20px 46px;
    margin: 65px auto 0;
}
.wait_cont .wait_info li{
    display: flex;
    align-items: center;
    font-size: 20px;
    letter-spacing: -0.8px;
    margin-bottom: 20px;
}
.wait_cont .wait_info li:last-child{
    margin-bottom: 0;
}
.wait_cont .wait_info li span{
    display: inline-block;
    color: #555;
    width: 194px;
}
.wait_cont .wait_info li p{
    color: #333;
    font-weight: 500;
}
.wait_cont .wait_btn{
    display: block;
    width: 100%;
    max-width: 360px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: #333333;
    border-radius: 41px;
    font-size: 24px;
    letter-spacing: -0.96px;
    color: #fff;
    font-weight: 500;
    margin: 62px auto 0;
}
@media screen and (max-width: 1920px) {
    .intro_visual .intro_visual_obj1{
        width: 1211px;
        height: 1013px;
        padding-bottom: 0;
    }
    .intro_visual .intro_visual_obj2{
        width: 695px;
        height: 868px;
        padding-bottom: 0;
    }
}
@media screen and (max-width: 1550px) {
    .intro_visual .intro_visual_obj1{
        left: -20%;
    }
    .intro_visual .intro_visual_obj2{
        right: -20%;
    }
    /* .intro_contents .intro_section4 .imgbox{
        max-width: calc(100% - 93px);
        margin:0;
    } */
    /* .intro_contents .intro_section4 .i_sec4_tab{
        left: auto;
        right: 0;
    } */
}
@media screen and (max-width: 1400px) {
    .intro_contents .intro_section3 .imgbox .swiper-button-prev{
        left: -41px;
    }
    .intro_contents .intro_section3 .imgbox .swiper-button-next{
        right: -41px;
    }
}
@media screen and (max-width: 1340px) {
    .intro_wrap .inner{
        width: calc(100% - 188px);
        max-width: 812px;
    }
    .intro_header{
        background: #fff;
        border-bottom: 1px solid #e6e6e6;
        height: 100px;
    }
    .intro_header h1 a{
        background: url(../images/logo-black.png) no-repeat top left;
        background-size: contain;
    }
    .intro_visual{
        margin-top: 100px;
        height: calc(100vh - 100px);
        background:#f5f7fa url(../images/main_obj_tab.png) no-repeat top center;
        background-size: cover;
    }
    .intro_visual .inner{
        width: calc(100% - 188px);
        min-width: calc(100% - 188px);
        max-width: 100%;
    }
    .intro_visual .intro_visual_obj1{
        display: none;
    }    
    .intro_visual .intro_visual_obj2{
        display: none;
    }
    .intro_visual h2{
        top: 20%;
    }
    .intro_visual h2 .h2_stxt1{
        font-size: 22px;
        letter-spacing: -1.7px;
    }
    .intro_visual h2 .h2_stxt2{
        font-size: 38px;
        letter-spacing: -0.78px;
        margin-top: 13px;
    }
    .intro_visual h2 p{
        font-size: 68px;
        letter-spacing: -2.46px;
        margin-top: 36px;
    }
    .intro_visual .visual_symbol{
        width: 448px;
        height: 400px;
        background-size: 100%;
        left: 550px;
        top: 13%;
    }
    .intro_contents .intro_section1 .desc_ico_list{
        flex-wrap: wrap;
        gap: 23px 82px;
        justify-content: center;
    }
    .intro_contents .intro_section1{
        padding-top: 53px;
        padding-bottom: 100px;
    }
    .intro_contents .intro_section1 .desc_h3 h3 br{
        display: block;
    }
    .intro_contents .intro_section2{
        padding-top: 89px;
        padding-bottom: 160px;
    }
    .intro_contents .intro_section2:before{
        width: 802px;
        height: 1117px;
        background-size: 100%;
        left: -145px;
    }
    .intro_contents .intro_section3{
        padding-bottom: 160px;
    }
    .intro_contents .intro_section3 .imgbox .swiper-button-prev{
        left: -71px;
    }
    .intro_contents .intro_section3 .imgbox .swiper-button-next{
        right: -71px;
    }
    .intro_contents .intro_section4 .imgbox{
        max-width: 100%;
    }
    .intro_contents .intro_section4 .i_sec4_tab{
        right: auto;
        left: calc(50% + 406px);
        margin-left: 13px;
    }
    .intro_contents .intro_section4:before{
        width: 709px;
        height: 1180px;
        background-size: 100%;
        top: -160px;
        right: -91px;
    }
    .intro_contents .intro_section5:before{
        width: 767px;
        height: 1059px;
        background-size: 100%;
        left: -85px;
        top: -227px;
    }
    .intro_contents .intro_section6:before{
        width: 825px;
        height: 1180px;
        background-size: 100%;
        right: -165px;
        top: -165px;
    }
    .intro_contents .intro_section7:before{
        width: 725px;
        height: 794px;
        background-size: 100%;
        left: -73px;
        top: -207px;
    }
    .intro_contents .intro_section8 .balloon_box{
        height: 545px;
        background: none;
    }
    .intro_contents .intro_section8 .balloon_box:before{
        content: "";
        width: 100%;
        height: 545px;
        background: url(../images/balloon-bg.png) no-repeat center top;
        background-size: auto 100%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
    .intro_contents .intro_section8 .balloon_box .balloon_bg{
        width: 852px;
        height: 483px;
        background: url(../images/balloon_line_t.png) no-repeat left top;
        right: auto;
        left: 53px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li{
        width: 178px;
        height: 215px;
        background-size: 100%;
        padding-top: 48px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li p{
        font-size: 19px;
        line-height: 1.42;
        letter-spacing: -0.76px;
        margin-bottom: 12px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li span{
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: -0.52px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li.balloon_1{
        left: 0;
        top: -78px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li.balloon_2{
        left: 356px;
        top: -8px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li.balloon_3{
        width: 235px;
        height: 273px;
        background-size: 100%;
        padding-top: 42px;
        left: 613px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li.balloon_3 p{
        font-size: 28px;
        line-height: 1;
        letter-spacing: -1.12px;
    }
    .intro_contents .intro_section8 .balloon_box .balloon li.balloon_3 span{
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: -0.6px;
    }
    .intro_contents .intro_section9 h4{
        width: 876px;
        margin-left: -32px;
    }
    .intro_contents .intro_section9 h4{
        letter-spacing: -3px;
    }
    .intro_contents .intro_section9 .cloud_list{
        gap: 0 28px;
    }
    .intro_contents .intro_section9 .cloud_list li{
        padding-left: 0;
        padding-top: 80px;
    }
    .intro_contents .intro_section9 .cloud_list li span br{
        display: none;
    }
    .intro_footer{
        padding-bottom: 68px;
    }
    .intro_footer .footer_txtbox{
        display: block;
    }
    .intro_footer .footer_txtbox .footer_txtbox_left{
        width: 100%;
    }
    .intro_footer .footer_txtbox .footer_txtbox_right{
        width: 100%;
    }
    .intro_footer .footer_txtbox .footer_contact{
        justify-content: flex-start;
        margin-top: 25px;
    }
    .intro_footer .topBtn{
        top: auto;
        bottom: 0;
    }
}
@media screen and (max-width: 1200px) {
    .intro_visual{
        height: auto;
        background:#f5f7fa;
    }
    .intro_visual .intro_visual_bg{
        display: block;
    }
    .intro_visual .intro_visual_bg .tab_img{
        display: block;
    }
    .intro_visual .intro_visual_obj1{
        width: 631px;
        height: 528px;
        left: 0;
    }
    .intro_visual .intro_visual_obj2{
        width: 362px;
        height: 452px;
        top: 47px;
        right: 0;
    }
    .intro_visual .inner{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }
    .intro_visual h2 .h2_stxt1{
        font-size: 17px;
        line-height: 1.47;
    }
    .intro_visual h2 .h2_stxt2{
        font-size: 26px;
        margin-top: 3px;
    }
    .intro_visual h2 p{
        font-size: 41px;
        margin-top: 17px;
    }
    .intro_visual .visual_symbol{
        width: 259px;
        height: 235px;
        background-size: 100%;
        left: 50%;
        top: 16%;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(1){
        transition-delay: 1.4s;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(2){
        transition-delay: 1.5s;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(3){
        transition-delay: 1.6s;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(4){
        transition-delay: 1.7s;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(5){
        transition-delay: 1.8s;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(6){
        transition-delay: 1.9s;
    }
    .intro_contents .intro_section1.show .desc_ico_list li:nth-child(7){
        transition-delay: 2s;
    }
    .intro_contents .intro_section1.show .desc_h3 h3{
        transition-delay: 2.2s;
    }
    .intro_contents .intro_section1.show .desc_h3 p{
        transition-delay: 2.4s;
    }
    .intro_contents .intro_section4 .i_sec4_tab{
        display: none;
    }
    .intro_contents .intro_section4 .imgbox{
        margin-bottom: 92px;
    }
    .intro_contents .intro_section4 .swiper-pagination{
        display: flex;
    }
}
@media screen and (max-width: 980px) {
    .intro_header .intro_gnb li.pdf_btn{
        position: absolute;
        right: 25px;
        top: 100%;
        margin-top: 20px;
    }
    .intro_header.active .intro_gnb li.pdf_btn{
        display: none;
    }
    .intro_contents .intro_section8 .balloon_box{
        height: auto;
        margin-top: 63px;
    }
    .intro_contents .intro_section8 .balloon_box:before{
        height: calc(100% - 78px);
    }
    .intro_contents .intro_section8 .balloon_box .balloon_bg{
        display: none;
    }
    .intro_contents .intro_section8 .balloon_box .balloon{
        display: none;
    }
    .intro_contents .intro_section8 .txtbox{
        padding-bottom: 0;
    }
    .intro_contents .intro_section8 .balloon_box .balloon_mbox{
        display: block;
    }
    .intro_contents .intro_section8 .balloon_box .balloon_mbox .balloon_mbox_timg{
        display: block;
    }
    .intro_contents .intro_section9 h4{
        width: 100%;
        margin-left: 0;
    }
    .intro_contents .intro_section9 h4 em{
        display: block;
    }
    .intro_contents .intro_section9 .cloud_list{
        flex-wrap: wrap;
        gap: 22px 0;
    }
    .intro_contents .intro_section9 .cloud_list li{
        width: 100%;
        padding-left: 72px;
        padding-top: 0;
    }
    .intro_footer .footer_txtbox .footer_info span em:last-child{
        width: 100%;
        padding-left: 0;
    }
    .intro_footer .footer_txtbox .footer_info span em:after{
        display: none;
    }
    .intro_footer .footer_txtbox .footer_info span:first-child em:first-child:after{
        display: block;
    }
    .index_wrap .intro_contents .intro_section9 .textbox p{
        font-size: 43px;
        line-height: 53px;
    }
    .index_wrap .intro_contents .intro_section9 .textbox p em{
        font-size: 72px;
        margin-top: 20px;
    }
    .index_wrap .intro_header .intro_gnb li.pdf_btn{
        position: static;
        margin-top: 0;
    }
}
@media screen and (max-width: 800px) {
    html{
        font-size: 1.5px;
    }
    .intro_header{
        height: 60px;
        padding: 10rem 10rem 10rem 14rem;
    }
    .intro_header h1{
        width: 91rem;
        height: 13rem;
    }
    .intro_header .intro_gnb li > a{
        font-size: 8rem;
        height: 19rem;
        padding: 0 10rem;
        line-height: 17rem;
    }
    .intro_header .intro_gnb li.profile > a{
        line-height: 19rem;
    }
    .intro_header .intro_gnb li.pdf_btn{
        margin-top: 10rem;
        right: 10rem;
    }
    .intro_visual .inner{
        width: calc(100% - 20rem);
    }
    .intro_visual{
        margin-top: 40rem;
        height: auto;
    }
    .intro_visual{
        background-position: right 30% center;
    }
    .intro_visual .intro_visual_obj1{
        width: 315rem;
        height: 264rem;
        background-size: 100%;
        opacity: 1;
        transform: translate(0,0);
    }
    .intro_visual .intro_visual_obj2{
        width: 181rem;
        height: 226rem;
        background-size: 100%;
        opacity: 1;
        transform: translate(0,0);
    }
    .intro_visual.show .intro_visual_obj1{
        transition-delay: 0s;
    }
    .intro_visual.show .intro_visual_obj2{
        transition-delay: 0s;
    }
    .intro_visual .inner{
        display: flex;
        flex-direction: column;
    }
    .intro_wrap .inner{
        width: calc(100% - 20rem);
    }
    .intro_contents .intro_section{
        /* height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
    }
    .intro_contents .intro_section1{
        padding-top: 117rem;
        padding-bottom: 0;
    }
    .intro_contents .intro_section1:before{
        background-size: 100% 100%;
        height: auto;
        padding-bottom: 96%;
    }
    .intro_contents .intro_section1 .desc_ico_list{
        gap: 21rem 11rem;
        padding-bottom: 36rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li i{
        width: 39rem;
        height: 39rem;
        border-radius: 13rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(1) i{
        background-size: 13rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(2) i{
        background-size: 16rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(3) i{
        background-size: 12rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(4) i{
        background-size: 16rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(5) i{
        background-size: 15rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(6) i{
        background-size: 15rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li:nth-child(7) i{
        background-size: 15rem;
    }
    .intro_contents .intro_section1 .desc_ico_list li p{
        margin-top: 4rem;
        font-size: 10rem;
        letter-spacing: -0.4rem;
    }
    .intro_contents .intro_section .desc_h3 h3{
        font-size: 21rem;
        line-height: 24rem;
        letter-spacing: -0.85rem;
    }
    .intro_contents .intro_section .desc_h3 p{
        font-size: 13rem;
        line-height: 20rem;
        letter-spacing: -0.53rem;
    }
    .intro_contents .intro_section2{
        padding: 0;
        margin-top: 130rem;
    }
    .intro_contents .intro_section .txtbox{
        margin-top: 10rem;
    }
    .intro_contents .intro_section .txtbox p{
        font-size: 16rem;
        line-height: 21rem;
        letter-spacing: -0.64rem;
        margin-bottom: 8rem;
        word-break: keep-all;
    }
    .intro_contents .intro_section .txtbox span{
        font-size: 11rem;
        line-height: 15rem;
        letter-spacing: -0.43rem;
    }
    .intro_contents .intro_section3{
        padding: 0;
        margin-top: 130rem;
    }
    .intro_contents .intro_section3 .i_sec3_tab{
        gap: 0 6rem;
        margin-bottom: 7rem;
    }
    .intro_contents .intro_section3 .i_sec3_tab li a{
        width: 48rem;
        height: 19rem;
        font-size: 8rem;
        line-height: 17rem;
        letter-spacing: -0.4rem;
    }
    .intro_contents .intro_section3 .imgbox .swiper-button-prev, .intro_contents .intro_section3 .imgbox .swiper-button-next{
        display: none;
    }
    .intro_contents .intro_section .imgbox{
        margin-bottom: 28rem;
    }
    .intro_contents .intro_section4{
        padding:0;
        margin-top: 130rem;
    }
    .intro_contents .intro_section4 .imgbox{
        margin-bottom: 48rem;
    }
    .intro_contents .intro_section4 .swiper-pagination{
        display: flex;
        margin-top: 20rem;
    }
    .intro_contents .intro_section5{
        padding: 0;
        margin-top: 130rem;
    }
    .intro_contents .intro_section6{
        padding: 0;
        margin-top: 130rem;
    }
    .intro_contents .intro_section7{
        padding: 0;
        margin-top: 130rem;
    }
    .intro_contents .intro_section8{
        height: auto;
        padding: 0;
        padding-top: 130rem;
    }
    .intro_contents .intro_section8 .balloon_box{
        margin-top: 32rem;
    }
    .intro_contents .intro_section8 .balloon_box:before{
        height: calc(100% - 39rem);
    }
    .intro_contents .intro_section9{
        height: auto;
        padding-bottom: 55rem;
        padding-top: 53rem;
    }
    .intro_contents .intro_section9 h4{
        font-size: 20rem;
        line-height: 24rem;
        letter-spacing: -0,8rem;
    }
    .intro_contents .intro_section9 .startBtn{
        width: 100%;
        max-width: 230rem;
        height: 38rem;
        border-radius: 13rem;
        font-size: 15rem;
        letter-spacing: -0.59rem;
        line-height: 38rem;
        margin-top: 28rem;
    }
    .intro_contents .intro_section9.show .cloud_list{
        padding-top: 27rem;
        gap: 11rem 0;
    }
    .intro_contents .intro_section9.show .cloud_list li{
        padding-left: 35rem;
    }
    .intro_contents .intro_section9 .cloud_list li em{
        width: 27rem;
        height: 32rem;
        background-size: 100%;
        font-size: 10rem;
        line-height: 14rem;
        letter-spacing: -0.4rem;
        padding-left: 4rem;
        top: -7rem;
    }
    .intro_contents .intro_section9 .cloud_list li p{
        font-size: 13rem;
        line-height: 20rem;
        letter-spacing: -0.53rem;
        margin-bottom: 2rem;
    }
    .intro_contents .intro_section9 .cloud_list li span{
        font-size: 9rem;
        line-height: 13rem;
        letter-spacing: -0.37rem;
    }
    .intro_footer{
        padding: 22rem 0 26rem;
    }
    .intro_footer .footer_logo{
        width: 91rem;
        height: 13rem;
        background-size: 100%;
    }
    .intro_footer .footer_txtbox{
        margin-top: 12rem;
    }
    .intro_footer .footer_txtbox .footer_menu{
        gap: 0 24rem;
    }
    .intro_footer .footer_txtbox .footer_menu li a{
        font-size: 9rem;
        line-height: 18rem;
        letter-spacing: -0.37rem;
    }
    .intro_footer .footer_txtbox .footer_info{
        font-size: 9rem;
        line-height: 16rem;
        letter-spacing: -0.37rem;
    }
    .intro_footer .footer_txtbox .footer_info .copy{
        font-size: 9rem;
        line-height: 16rem;
        letter-spacing: -0.37rem;
    }
    .intro_footer .footer_txtbox .footer_contact span{
        font-size: 9rem;   
        line-height: 16rem;
        letter-spacing: -0.37rem;
        margin-right: 26rem;
    }
    .intro_footer .footer_txtbox .footer_contact span i{
        margin-right: 4rem;
    }
    .intro_footer .footer_txtbox .footer_contact .number i{
        width: 13rem;
        height: 13rem;
        background-size: contain;
    }
    .intro_footer .footer_txtbox .footer_contact .mail i{
        width: 13rem;
        height: 11rem;
        background-size: contain;
    }
    .intro_footer .footer_txtbox .footer_info span em:after{
        height: 9rem;
    }
    .intro_footer .topBtn{
        font-size: 6rem;
        line-height: 12rem;
        letter-spacing: -0.24rem;
        bottom: -10rem;
    }
    .intro_footer .topBtn i{
        width: 8rem;
        height: 16rem;
        background-size: 100%;
    }
    .login_modal .login_modal_box{
        top: 33rem;
        right: 10rem;
        width: 220rem;
        height: auto;
        padding: 25rem 20rem 12rem;
    }
    .login_modal .login_tit{
        font-size: 12rem;
        margin-bottom: 6rem;
    }
    .login_modal span{
        font-size: 9rem;
    }
    .login_modal .goLogin{
        margin-top: 11rem;
    }
    .login_modal .policy_txt{
        margin-top: 11rem;
    }
    .login_modal .modal_close{
        width: 12rem;
        height: 12rem;
        background-size: contain;
        right: 12rem;
        top: 12rem;
    }
    .term_cont{
        padding-top: 40rem;
        padding-bottom: 70rem;
    }
    .term_cont h2{
        padding-top: 43rem;
        font-size: 23rem;
        letter-spacing: -1px;
    }
    .term_cont .title_desc{
        font-size: 10rem;
        margin-top: 15rem;
    }
    .term_cont .term_box{
        margin-top: 23rem;
    }
    .term_cont .term_box h3{
        font-size: 13rem;
        letter-spacing: -0.6px;
        margin-bottom: 12rem;
    }
    .term_cont .term_box .term_col{
        margin-bottom: 12rem;
    }
    .term_cont .term_box .term_tit{
        font-size: 11rem;
    }
    .term_cont .term_box span{
        font-size: 10rem;
        line-height: 15rem;
        margin-top: 6rem;
    }
    .term_cont .term_box ul li{
        font-size: 10rem;
        line-height: 15rem;
    }
    .term_cont .term_box ul li:before{
        font-size: 10rem;
        line-height: 15rem;
    }
    .term_cont .term_box .r_num > li{
        padding-left: 12rem;
    }
    .term_cont .term_box ul ul{
        margin: 6rem 0 9rem;
    }
    .term_cont .term_box .o_num > li{
        padding-left: 10rem;
    }
    .term_cont .term_box .o_num > li:nth-child(10){
        padding-left: 15rem;
    }
    .term_cont .term_box .o_num > li:nth-child(11){
        padding-left: 15rem;
    }
    .term_cont .term_box .o_num > li:nth-child(12){
        padding-left: 15rem;
    }
    .term_cont .term_box .o_num > li:nth-child(13){
        padding-left: 15rem;
    }
    .term_cont .term_box .o_num > li:nth-child(14){
        padding-left: 15rem;
    }
    .term_cont .term_box .term_tit.bylaw_tit{
        font-size: 10rem;
    }
    .index_wrap .intro_contents .intro_section9 .textbox p{
        font-size: 25rem;
        line-height: 30rem;
    }
    .index_wrap .intro_contents .intro_section9 .textbox p em{
        font-size: 40rem;
        margin-top: 16rem;
    }
    .wait_cont{
        padding-top: 40rem;
    }
    .wait_cont .wait_h3{
        padding-top: 82rem;
        font-size: 18rem;
    }
    .wait_cont .wait_desc{
        margin-top: 16rem;
        font-size: 12rem;
        line-height: 17rem;
    }
    .wait_cont .wait_info{
        padding: 19rem 10rem 23rem;
        margin-top: 33rem;
    }
    .wait_cont .wait_info li{
        font-size: 10rem;
        margin-bottom: 10rem;
        letter-spacing: -0.8rem;
    }
    .wait_cont .wait_info li span{
        width: 97rem;
    }
    .wait_cont .wait_btn{
        height: 35rem;
        line-height: 35rem;
        font-size: 12rem;
        margin-top: 31rem;
    }
}
@media screen and (max-width: 640px) {
    .login_modal .login_modal_box{
        width: calc(100% - 20rem);
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    .intro_footer .footer_txtbox .footer_menu{
        gap: 0 12rem;
    }
    .intro_visual .intro_visual_bg .tab_img{
        display: none;
    }
    .intro_visual .intro_visual_bg .m_img{
        display: block;
    }
    .intro_visual h2{
        order: 2;
        position: static;
        margin-top: 87rem;
        color: #333333;
        padding-left: 10rem;
    }
    .intro_visual .visual_symbol{
        order: 1;
        position: static;
        width: 150rem;
        height: 150rem;
        background-size: 100%;
        margin: 31rem auto 0;
        transform: none;
    }
    .intro_visual h2 .h2_stxt1{
        font-size: 11rem;
        line-height: 17rem;
        letter-spacing: -1.13rem;
    }
    .intro_visual h2 .h2_stxt2{
        font-size: 17rem;
        line-height: 1;
        letter-spacing: -0.52rem;
        margin-top: 2rem;
    }
    .intro_visual h2 p{
        font-size: 27rem;
        line-height: 1;
        letter-spacing: -1.64rem;
        margin-top: 11rem;
    }
    .intro_visual.show .visual_symbol{
        transition-delay: 0s;
    }
    .intro_visual.show h2 .h2_stxt1{
        transition-delay: 0.3s;
    }
    .intro_visual.show h2 .h2_stxt2{
        transition-delay: 0.6s;
    }
    .intro_visual.show h2 p{
        transition-delay: 0.9s;
    }
    .intro_contents .intro_section2:before{
        width: 250rem;
        height: 372rem;
        left: -60rem;
        top: 50%;
        transform: translateY(-50%);
    }
    .intro_contents .intro_section2 .imgbox .web_img{
        display: none;
    }
    .intro_contents .intro_section2 .imgbox .m_img{
        display: block;
    }
    .intro_contents .intro_section4:before{
        height: 349rem;
        top: 50%;
        background-size: auto 100%;
        transform: translateY(-50%);
        right: -114rem;
        margin-top: -110rem;
    }
    .intro_contents .intro_section5:before{
        height: 257rem;
        background-size: auto 100%;
        top: 50%;
        transform: translateY(-50%);
        left: -66px;
        margin-top: -29%;
    }
    .intro_contents .intro_section6:before{
        height: 393rem;
        background-size: auto 100%;
        top: 50%;
        transform: translateY(-50%);
        background-position: right center;
    }
    .intro_contents .intro_section7:before{
        width: 80%;
        background-size: 100%;
        left: -40px;
        top: -31%;
    }
    .index_wrap .intro_contents .intro_section9 .textbox p{
        font-size: 18rem;
        line-height: 22rem;
    }
    .index_wrap .intro_contents .intro_section9 .textbox p em{
        font-size: 27rem;
        margin-top: 10rem;
    }
    .wait_cont{
        /* padding: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        padding-bottom: 100rem;
    }
    .wait_cont .wait_desc em{
        display: block;
    }
    .wait_cont .wait_h3{
        padding-top: 41rem;
        font-size: 16rem;
    }
    .wait_cont .wait_h3 em{
        display: block;
    }
    .wait_cont .wait_desc{
        margin-top: 10rem;
        font-size: 10rem;
        line-height: 15rem;
    }
    .wait_cont .wait_info{
        margin-top: 20rem;
        padding: 15rem 10rem 17rem;
        padding-top: 15rem;
        padding-bottom: 17rem;
    }
    .wait_cont .wait_info li{
        font-size: 9rem;
    }
    .wait_cont .wait_info li span{
        width: 85rem;
    }
    .wait_cont .wait_btn{
        margin-top: 25rem;
        height: 30rem;
        line-height: 30rem;
        font-size: 10rem;
        max-width: 180rem;
    }
}
@media screen and (max-width: 550px) {
    .intro_contents .intro_section8 .balloon_box{
        margin-top: 0;
    }
    .intro_contents .intro_section8 .balloon_box:before{
        display: none;
    }
    .intro_contents .intro_section8 .balloon_box .balloon_mbox .balloon_mbox_timg{
        display: none;
    }
    .intro_contents .intro_section8 .balloon_box .balloon_mbox .balloon_mbox_mimg{
        display: block;
    }
    .intro_header .intro_gnb{
        gap: 0 5px;
    }
    .intro_header .intro_gnb li.profile > a{
        padding: 0;
    }
    .intro_header{
        padding-left: 5rem;
        padding-right: 5rem;
    }
}